import React from 'react';
import styled from 'styled-components';

const StyledCloseBtn = styled.div`
  .gclose {
    position: absolute;
    width: 35px;
    height: 35px;
    top: 15px;
    right: 20px;
    opacity: 0.7;
    background-color: rgba(0, 0, 0, 0.32);
    border-radius: 4px;
    z-index: 99999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: opacity 0.3s;
    &:hover {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.7);
    }
    svg {
      display: block;
      width: 20px;
      height: 20px;
      path {
        fill: white;
      }
    }
    @media (max-width: 900px) {
      top: 115px; /* Need to move down due to mobile header */
    }
  }
`;

const CloseButtonSvg = ({ onClick }) => {
  return (
    <StyledCloseBtn>
      <button className="gclose gbtn" ariaLabel="Close" data-taborder="3" onClick={onClick}>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <g>
            <g>
              <path d="M505.943,6.058c-8.077-8.077-21.172-8.077-29.249,0L6.058,476.693c-8.077,8.077-8.077,21.172,0,29.249C10.096,509.982,15.39,512,20.683,512c5.293,0,10.586-2.019,14.625-6.059L505.943,35.306C514.019,27.23,514.019,14.135,505.943,6.058z"></path>
            </g>
          </g>
          <g>
            <g>
              <path d="M505.942,476.694L35.306,6.059c-8.076-8.077-21.172-8.077-29.248,0c-8.077,8.076-8.077,21.171,0,29.248l470.636,470.636c4.038,4.039,9.332,6.058,14.625,6.058c5.293,0,10.587-2.019,14.624-6.057C514.018,497.866,514.018,484.771,505.942,476.694z"></path>
            </g>
          </g>
        </svg>
      </button>
    </StyledCloseBtn>
  );
};

export default CloseButtonSvg;
