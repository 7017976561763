import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Hero from '../components/Hero';
import Layout from '../components/Layout';
import FullscreenVideo from '../components/ServiceComponents/basics/fullscreen-video';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import { Helmet } from 'react-helmet';
import PlayButtonSvg from '../components/PlayButtonSvg';

const StyledIntro = styled.div`
  color: black;
  font-size: 32px;
  margin: 65px 0 60px;
  padding: 0 30px;
  h1 {
    margin-top: 0px;
    margin-bottom: 30px;
    text-transform: uppercase;
    text-align: center;
    font-family: 'Roboto';
    font-size: 1.814rem;
    letter-spacing: 2px;
    font-weight: 400;
    transition: all 200ms linear;
    -webkit-font-smoothing: antialiased;
    @media (max-width: 600px) {
      font-size: 1.4rem;
    }
  }
  p {
    font-weight: 400;
    font-size: 1.19rem;
    max-width: 971px;
    line-height: 2.4rem;
    margin: 0 auto 20px;
    letter-spacing: 0.9px;
    transition: all 200ms linear;
    -webkit-font-smoothing: antialiased;
    a {
      color: inherit;
      &:hover {
        color: #0095c8;
      }
    }
    @media (max-width: 600px) {
      font-size: 1rem;
    }
  }
`;

const StyledImages = styled.div`
  font-size: 16px;
  @media screen and (max-width: 480px) {
    font-size: 13px;
  }
  .image-wrapper {
    position: relative;
    padding-bottom: 60px;
    /* &[data-fullwidth='true'] {
      .gatsby-image-wrapper {
        margin: 0 auto;
        img {
          max-width: 1920px;
          height: 100%;
          object-fit: none;
        }
      }
    } */
    .gatsby-image-wrapper {
      margin: 0 auto;
    }
    .gatsby-image-wrapper-constrained {
      display: block;
    }
  }
  .caption {
    position: relative;
    text-align: center;
    color: #0095c8;
    max-width: 570px;
    margin: 0 auto;
    /* border-top: 2px solid #0095c8; */
    padding: 50px 30px 45px;
    background-color: transparent;
    z-index: 50;
    font-family: 'Oswald';
    text-transform: uppercase;
    letter-spacing: calc((0.5em - 7px));
    font-size: 1.5em;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    display: block;
    box-sizing: border-box;
    transform: all 200ms linear;
  }
`;

const StyledVideo = styled.div`
  padding-bottom: 60px;
  .preview-img {
    border-radius: 10px;
  }
`;

function fullWidthImage(img) {
  img.layout = 'fullWidth';
  img.images.fallback.sizes = '100vw';
  img.images.sources = img.images.sources.map((source) => {
    return { ...source, sizes: '100vw' };
  });
  return img;
}

const PageTemplate = ({ className, fields }) => {
  return (
    <>
      <Hero
        heroImg={fields.hero_image.childImageSharp.gatsbyImageData}
        label="Featured"
        className="featured-hero"
        alt_text={fields.hero_alt}
      />
      <div className="case-study-content">
        <div
          className="logo"
          style={{
            maxWidth: fields.logo_image.childImageSharp.gatsbyImageData.width + 'px',
            margin: '0 auto',
          }}
        >
          <GatsbyImage image={fields.logo_image.childImageSharp.gatsbyImageData} alt={fields.logo_alt} />
        </div>

        <StyledIntro>
          <h1>{fields.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: fields.description }} />
        </StyledIntro>

        {/*
<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/815471394?h=cf3302438a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Charter Steel: Art of Steel Brand Video"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>

*/}

        <StyledImages>
          {fields.content.map((item, index) => {
            if (item.type === 'image' || !item.type) {
              const style = item.style || {};
              if (!style.maxWidth && item.layout != 'full-width') {
                style.maxWidth = item.img.childImageSharp.gatsbyImageData.width + 'px';
              }
              return (
                <div
                  className={'image-wrapper item-' + index}
                  key={index}
                  style={item.zIndex ? { zIndex: item.zIndex } : undefined}
                >
                  {item.caption && <h2 className="caption">{item.caption}</h2>}
                  <GatsbyImage
                    image={
                      item.layout == 'full-width'
                        ? fullWidthImage(item.img.childImageSharp.gatsbyImageData)
                        : item.img.childImageSharp.gatsbyImageData
                    }
                    style={style}
                    // style={{
                    //   maxWidth: item.img.childImageSharp.gatsbyImageData.width + 'px',
                    // }}
                    alt={item.alt}
                  />
                </div>
              );
            } else if (item.type === 'video')
              return (
                <StyledVideo style={{ maxWidth: '1125px', margin: '0 auto' }} key={index}>
                  <h2 className="caption">{item.caption}</h2>

                  <FullscreenVideo
                    mapUrl=""
                    previewImg={item.img.childImageSharp.gatsbyImageData.images.fallback.src}
                    vimeoId={item.vimeoId}
                    vimeoHash={item.vimeoHash}
                    title={item.alt}
                  >
                    <PlayButtonSvg color={item.button_color || undefined} />
                  </FullscreenVideo>
                </StyledVideo>
              );
          })}
        </StyledImages>
        <div style={{ textAlign: 'center' }}>
          <Link
            className="cta"
            to="/case-studies/"
            style={{ color: 'black', borderColor: 'black' }}
            onClick={(e) => {
              e.preventDefault();
              navigate('/case-studies/#featured-projects');
            }}
          >
            See More Featured Projects
          </Link>
        </div>
      </div>
    </>
  );
};

const Page = ({ data }) => {
  let meta = data.site.siteMetadata;

  if (data.allAllFeaturedProjectPagesHJson.edges[0].node) {
    const page = data.allAllFeaturedProjectPagesHJson.edges[0].node;

    return (
      <Layout>
        <Helmet>
          <title>{page.meta_title}</title>
          <meta name="description" content={page.meta_desc} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={page.meta_title} />
          <meta property="og:description" content={page.meta_desc} />
          <meta property="og:url" content={meta.siteUrl + '/featured-project/' + page.path + '/'} />
          {page.meta_ogimg && <meta property="og:image" content={meta.siteUrl + page.meta_ogimg.publicURL} />}
        </Helmet>
        <PageTemplate className="featuredproject-page" fields={page} />
      </Layout>
    );
  } else {
    return <></>;
  }
};

export default Page;

export const pageQuery = graphql`
  query FeaturedProjectPageById($id: String!) {
    allAllFeaturedProjectPagesHJson(filter: { id: { eq: $id } }) {
      edges {
        node {
          path
          title
          meta_title
          meta_desc
          meta_ogimg {
            publicURL
          }
          description
          hero_image {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 1920)
            }
          }
          hero_alt
          logo_image {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
          logo_alt
          content {
            vimeoId
            vimeoHash
            button_color
            type
            layout
            img {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
            style {
              maxWidth
              width
              marginBottom
              marginTop
            }
            caption
            zIndex
            alt
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
