import React from 'react';

const PlayButtonSvg = ({ color = '#00ADEF' }) => (
  <svg width="183" height="183" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 183 183">
    <g clipPath="url(#a)">
      <path
        d="M183 91.5C183 40.984 142.016 0 91.5 0S0 40.984 0 91.5 40.984 183 91.5 183 183 142.016 183 91.5Z"
        fill="#fff"
        fillOpacity=".8"
      />
      <g filter="url(#b)">
        <path
          d="M163 91.5C163 52.026 130.974 20 91.5 20S20 52.026 20 91.5 52.026 163 91.5 163 163 130.974 163 91.5Z"
          fill="#fff"
        />
        <path
          d="M160.125 91.5c0-37.887-30.738-68.625-68.625-68.625S22.875 53.613 22.875 91.5s30.738 68.625 68.625 68.625 68.625-30.738 68.625-68.625Z"
          stroke={color}
          strokeWidth="8.062"
          strokeMiterlimit="10"
        />
        <path
          d="m77.318 119.536 40.906-24.712a3.888 3.888 0 0 0 1.868-3.324 3.893 3.893 0 0 0-1.868-3.324L77.318 63.464a3.853 3.853 0 0 0-5.834 3.327v49.418a3.852 3.852 0 0 0 5.834 3.327Z"
          fill={color}
        />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h183v183H0z" />
      </clipPath>
      <filter
        id="b"
        x=".844"
        y="8.844"
        width="181.312"
        height="181.312"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1777_404" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feMorphology radius="6" operator="dilate" in="SourceAlpha" result="effect2_dropShadow_1777_404" />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="6" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
        <feBlend in2="effect1_dropShadow_1777_404" result="effect2_dropShadow_1777_404" />
        <feBlend in="SourceGraphic" in2="effect2_dropShadow_1777_404" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default PlayButtonSvg;
