import React, { useState } from 'react';
import styled from 'styled-components';
import Video from './video';
import VimeoEmbed from './vimeo-embed';
import CloseButtonSvg from '../../CloseButtonSvg';

const Wrap = styled.div`
  width: 100%;
  position: relative;
  .preview-img {
    width: 100%;
    display: block;
    cursor: pointer;
    max-width: 95%;
    margin: 0 auto;
  }

  .playBtn {
    position: absolute;
    cursor: pointer;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: transform 400ms cubic-bezier(0.645, 0.045, 0.355, 1);
    display: flex;
    flex-direction: column;
    max-height: 34%;
    svg {
      width: auto;
      height: 100%;
    }
  }
  &:hover .playBtn {
    transform: translate(-50%, -50%) scale(1.05);
  }
  .playBtn:hover {
    transform: translate(-50%, -50%) scale(1.11);
  }
  .video-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: black;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: stretch;
    transition: transform 400ms cubic-bezier(0.645, 0.045, 0.355, 1);
    /* transform: scale(0) translate(0px, 0px);  */

    &.hide {
      /* display: none; */
    }
    &.close {
    }
    &.open {
      visibility: visible;
      z-index: 111;
      transform: scale(1) translate(0px, 0px) !important;
    }
  }
`;

function serviceBorder(props) {
  let [popupClass, setPopupClass] = useState('');
  let [playOverride, setPlayOverride] = useState(false);

  let timeoutPointer;
  const openAndPlay = () => {
    // setPopupClass('close');
    setPopupClass('open');

    clearTimeout(timeoutPointer);
    timeoutPointer = setTimeout(() => {
      setPlayOverride(true);
    }, 400);
  };
  const close = () => {
    setPopupClass('close');

    clearTimeout(timeoutPointer);
    // timeoutPointer = setTimeout( () => {
    //   setPopupClass('close');
    // }, 400);
    setPlayOverride(false);
  };
  const completeEvent = () => {
    // console.log('complete');
    close();
  };

  let translateX = props.translateX ? props.translateX : `0px`;
  return (
    <Wrap>
      <img className="preview-img" src={props.previewImg} onClick={() => openAndPlay()} />
      {props.children && (
        <div className="playBtn" onClick={() => openAndPlay()}>
          {props.children}
        </div>
      )}
      <div
        className={`video-wrap ${popupClass} `}
        onClick={() => close()}
        style={{ transform: `scale(0) translate(${translateX}, 0px)` }}
      >
        {props.vimeoId ? (
          <VimeoEmbed videoId={props.vimeoId} videoHash={props.vimeoHash} visible={playOverride} title={props.title} />
        ) : (
          <Video mapUrl={props.mapUrl} playOverride={playOverride} onComplete={completeEvent} />
        )}
        <CloseButtonSvg onClick={() => close()} />
      </div>
    </Wrap>
  );
}
export default serviceBorder;
