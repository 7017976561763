import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import Hls from 'hls.js';
// import useVisibility from '../../../utils/useVisibility';

const VideoEl = styled.video`
  width: 100%;
  max-height: 100%;
  box-shadow: 0 0 10px -3px black;
`;

function Video(props) {
  let videoRef = useRef();
  // const updateVisibility = (isVisible) => {
  //   if(!videoRef || !videoRef.current) {
  //     return;
  //   }
  //   if(isUserPaused) {
  //     return;
  //   }
  //   if(isVisible) {
  //     videoRef.current.play();
  //   } else {
  //     videoRef.current.pause();
  //   }
  // };
  // let [ isVisible, videoRef ] = useVisibility(500, 300, updateVisibility);
  let [isUserPaused, setUserPaused] = useState(false);
  let loadTime = null;

  const userClick = () => {
    if (!videoRef || !videoRef.current) {
      return;
    }
    let play = videoRef.current.paused ? true : false;

    if (props.playOverride === true || props.playOverride === false) {
      play = props.playOverride;
    }

    if (play) {
      setUserPaused(false);
      videoRef.current.play();
    } else {
      setUserPaused(true);
      videoRef.current.pause();
    }
  };
  const videoProgress = function() {
    if (videoRef && videoRef.current) {
      const percent = (videoRef.current.currentTime / videoRef.current.duration) * 100;
      // console.log(`video progress ${percent}%`);
      if (percent > 96) {
        videoRef.current.currentTime = 9.12;
      }
      if (window.performance.now() - loadTime > 60000) {
        videoRef.current.removeEventListener('timeupdate', videoProgress);
      }
    }
  };

  const ended = function() {
    // console.log('ended');
    if (typeof props.onComplete === 'function') {
      props.onComplete();
    }
  };

  const loadVideo = function() {
    loadTime = window.performance.now();
    // if( videoRef.current ) {
    //   videoRef.current.addEventListener('timeupdate', videoProgress);
    // }

    videoRef.current.removeEventListener('ended', ended);
    videoRef.current.addEventListener('ended', ended, false);

    //TODO: Some sort of fallback option if Hls is NOT supported?
    if (Hls.isSupported()) {
      var video = videoRef.current;
      var hls = new Hls();
      // bind them together
      hls.attachMedia(video);
      hls.on(Hls.Events.MEDIA_ATTACHED, function() {
        hls.loadSource(props.mapUrl);
      });
    }
  };

  useEffect(() => {
    // loadTime = window.performance.now();
    loadVideo();
  }, [props.mapUrl]);

  useEffect(() => {
    userClick();
  }, [props.playOverride]);

  return <VideoEl ref={videoRef} poster={props.previewImg} onClick={() => userClick()} />;
}

export default Video;
