import React from 'react';

//If further control is needed, see: https://developer.vimeo.com/player/sdk
const VimeoEmbed = ({ videoId, videoHash, videoTitle, visible }) => {
  return (
    <div style={{ width: '100%', padding: '56.25% 0 0 0', position: 'relative' }}>
      {visible && (
        <iframe
          src={`https://player.vimeo.com/video/${videoId}?${
            videoHash && `h=${videoHash}&amp;`
          }badge=0&amp;autoplay=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
          title={videoTitle}
        ></iframe>
      )}
    </div>
  );
};

export default VimeoEmbed;
